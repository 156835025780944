import * as React from "react"
import {
  FaFacebookSquare as Facebook,
  FaTwitterSquare as Twitter,
  FaInstagram as Instagram,
  FaLinkedin as Linkedin,
  FaGithubSquare as Github
} from "react-icons/fa"

export const menuItems = [
  {
    path: "/",
    text: "home",
  },
  {
    path: "/blog",
    text: "blog",
  },
  {
    path: "/contact",
    text: "contact",
  },
]

export const socialItems = [
  {
    path: "https://www.linkedin.com/in/alfie-mills/",
    icon: <Linkedin />,
  },
  {
    path: "https://github.com/Alfie-Mills",
    icon: <Github />,
  },
]
